import React, { Component } from "react"
import s from "./test.module.css"
import FormTem from "../../components/form"
export default class testPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  testFn(e) {
    console.log(e + '')
  }
  render() {
    return (
      <div className={s.testMain}>
        <FormTem pfn={this.testFn.bind(this)}></FormTem>
      </div>
    )
  }

}
