import React, { Component } from "react"
import s from "./form.module.css"
import api from './api'
export default class FormTem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSend: true,
      errMsg: '',
      codeSrc: '',
      contactsName: '',
      company: '',
      contactsPhone: '',
      smsCode: '',
      contactsOccupation: '',
      interestedProduct: '',
      imageCode: '',
      allowClick: false,
      isRead: false,
      showDialog: false,
      yzmBtnTxt: '获取验证码',
      pMsg: {
        name: true,
        phone: true,
        code: true,
        company: true,
      }
    }
    this.getCodeImg = this.getCodeImg.bind(this);
    this.getYzm = this.getYzm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.sendSms = this.sendSms.bind(this);
    this.checkData = this.checkData.bind(this);
  }
  handleInputChange(event) {
    let { pMsg } = this.state
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    pMsg = {
      name: true,
      phone: true,
      code: true,
      company: true,
    }
    this.setState({
      [name]: value,
      pMsg
    }, () => {
      const { company, contactsName, contactsPhone, smsCode, isRead } = this.state
      const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      const codeReg = /^[0-9]{6}$/;
      let allowClick = false
      if (company && contactsName && phoneReg.test(contactsPhone) && codeReg.test(smsCode) && isRead) {
        allowClick = true
      }
      this.setState({
        allowClick
      });
    });
  }

  checkData() {
    const { contactsName, company, contactsPhone, smsCode, pMsg } = this.state
    const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    const codeReg = /^[0-9]{6}$/;
    if (contactsName === '') {
      pMsg.name = false
      this.setState({
        pMsg
      });
    } else if (company === '') {
      pMsg.company = false
      this.setState({
        pMsg
      });
    } else if (!phoneReg.test(contactsPhone)) {
      pMsg.phone = false
      this.setState({
        contactsPhone: '',
        pMsg
      });
    } else if (!codeReg.test(smsCode)) {
      pMsg.code = false
      this.setState({
        smsCode: '',
        pMsg
      });
    }
  }
  validateForm(e) {
    const { isSend } = this.state
    if (!isSend) {
      return false
    }
    e.preventDefault()
    this.checkData()
    if (!this.state.allowClick) {
      return false
    }
    this.setState({
      isSend: false
    })
    const { smsCode, contactsName, company, contactsPhone, contactsOccupation, interestedProduct } = this.state
    const data = {
      smsCode,
      follow: {
        contactsName,
        company,
        contactsPhone,
        contactsOccupation,
        interestedProduct
      }
    }
    fetch(api.follows, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(res => res.json()).then((myJson) => {
      if (myJson.msg !== 'ok') {
        this.setState({
          isSend: true
        })
      }
      this.setState({
        errMsg: myJson.msg,
        showDialog: true
      })
    })
      .catch(error => console.error('Error:', error))
  }
  getYzm() {
    const { contactsPhone, pMsg, yzmBtnTxt } = this.state
    if (yzmBtnTxt !== '获取验证码') {
      return false
    }
    const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!phoneReg.test(contactsPhone)) {
      pMsg.phone = false
      this.setState({
        contactsPhone: '',
        pMsg
      });
      return false
    }
    this.getCodeImg()
    this.setState({
      showDialog: true
    })
  }
  getCodeImg() {
    fetch(`${api.image}?${Date.now()}`, {
      method: 'get',
    }).then(res => {
      this.setState({
        codeSrc: res.url
      })
    })
      .catch(error => console.error('Error:', error))
  }
  sendSms() {
    this.setState({
      showDialog: false
    })
    let setI = null, timeNum = 60
    const { imageCode, contactsPhone } = this.state
    const data = {
      imageCode,
      contactsPhone
    }
    fetch(api.sms, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(res => res.json()).then((myJson) => {
      if (myJson.code !== 200) {
        this.setState({
          errMsg: '验证码输入错误',
          showDialog: true
        })
      } else {
        clearInterval(setI)
        setI = setInterval(() => {
          timeNum -= 1;
          this.setState({
            yzmBtnTxt: `(${timeNum})秒`
          })
          if (timeNum <= 0) {
            clearInterval(setI)
            this.setState({
              yzmBtnTxt: '获取验证码'
            })
          }
        }, 1000)
      }
    })
      .catch(error => console.error('Error:', error))
  }
  returnDiaCon() {
    const { errMsg } = this.state
    if (errMsg) {
      return (
        <div className={s.dialogBox} onClick={() => {
          this.setState({
            showDialog: false,
            errMsg: ''
          })
        }}>
          <div className={s.alertTitle}>{errMsg === 'ok' ? '感谢填写' : '提交失败'}</div>
          <div className={s.alertContent}>{errMsg === 'ok' ? '安全专家将在24小时内与您联系!' : errMsg}</div>
          <div className={s.alertBtn} onClick={() => {
            this.props.pfn(errMsg)
          }}>{errMsg === 'ok' ? '好的' : '知道了'}</div>
        </div>
      )
    } else {
      return (
        <div className={s.dialogBox}>
          <div className={s.dialogInp}>
            <input name="imageCode" className={s.dialogYzm} placeholder="请输入验证码" value={this.state.imageCode} onInput={this.handleInputChange}></input>
            <img src={this.state.codeSrc} onClick={this.getCodeImg} title="换一张"></img>
          </div>
          <div className={s.dialogBtn} onClick={this.sendSms}>发送短信</div>
        </div>
      )
    }

  }
  render() {
    return (
      <div className={s.main} style={{ transform: `scale(${this.props.sunScale})` }}>
        <div className={s.mainClo} onClick={() => {
          this.props.pfn('ok')
        }}></div>
        <div className={s.bg1}></div>
        <div className={s.bg2}></div>
        <div className={s.title}>立即抢购</div>
        <form className={s.formBox} onSubmit={this.validateForm}>
          <div className={s.inputBox}>
            <div className={s.icon1}></div>
            <input type="text"
              className={this.state.pMsg.name || s.invalid}
              name="contactsName"
              placeholder={this.state.pMsg.name ? '称呼' : '请输入称呼'}
              value={this.state.contactsName}
              onBlur={this.checkData}
              maxLength="30"
              onInput={this.handleInputChange} ></input>
          </div>
          <div className={s.inputBox}>
            <div className={s.icon2}></div>
            <input type="text"
              className={this.state.pMsg.company || s.invalid}
              name="company"
              placeholder={this.state.pMsg.company ? '公司/单位' : '请输入公司/单位名称'}
              onBlur={this.checkData}
              onInput={this.handleInputChange}
              maxLength="50"
              value={this.state.company} ></input>
          </div>
          <div className={s.inputBox}>
            <div className={s.icon3}></div>
            <input type="text"
              className={this.state.pMsg.phone || s.invalid}
              name="contactsPhone"
              maxLength="11"
              onBlur={this.checkData}
              onInput={this.handleInputChange}
              placeholder={this.state.pMsg.phone ? '联系电话' : '请输入正确手机号'}
              value={this.state.contactsPhone}
            ></input>
          </div>
          <div className={s.yzmBox}>
            <div className={s.inputBox1}>
              <div className={s.icon4}></div>
              <input type="text"
                name="smsCode"
                maxLength="6"
                onBlur={this.checkData}
                onInput={this.handleInputChange}
                placeholder={this.state.pMsg.code ? '短信验证码' : '请输入短信码'}
                value={this.state.smsCode} className={[s.yzm, this.state.pMsg.code || s.invalid].join(' ')}></input>
            </div>
            <div className={s.btnYzm} style={{ background: this.state.yzmBtnTxt === '获取验证码' ? '#E88127' : '#9e9e9e' }} onClick={this.getYzm}>
              {this.state.yzmBtnTxt}
            </div>
          </div>
          <div className={s.inputBox}>
            <div className={s.icon5}></div>
            <input type="text"
              name="contactsOccupation"
              maxLength="30"
              onInput={this.handleInputChange}
              placeholder="职位"
              value={this.state.contactsOccupation} ></input>
          </div>
          <div className={s.inputBox}>
            <div className={s.icon6}></div>
            <input type="text"
              name="interestedProduct"
              maxLength="50"
              onInput={this.handleInputChange}
              placeholder="需求描述"
              value={this.state.interestedProduct} ></input>
          </div>
          <div className={s.textBox}>
            <input type="checkbox"
              name="isRead"
              onInput={this.handleInputChange}
              value={this.state.isRead}
              className={s.textBoxInp}>
            </input>
            我已阅读并同意
            <a href="https://www.yunaq.com/agreement?set_area=zh" target="_blank" rel="noopener norefferrer">《知道创宇云安全服务用户协议》及《用户隐私协议》</a>
          </div>
          <input className={this.state.allowClick ? s.sendBox : s.sendBox0} type="submit" value="确定" ></input>
        </form>
        {/*  */}
        {this.state.showDialog ? (<div className={s.dialog}>
          <div className={s.dialogBg} onClick={() => {
            this.setState({
              showDialog: false,
              errMsg: ''
            })
          }}
          ></div>
          {this.returnDiaCon()}
        </div>) : null}
      </div >
    )
  }
}